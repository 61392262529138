import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../../../core/services/crud.service';
import {Faq} from '../../../../core/models/faq';
import {ContractOfEmployment} from '../../../../core/models/contract-of-employment';
import {Message} from '../../../../core/models/message';
import {Document} from '../../../../core/models/document';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {User} from '../../../../core/models/user';
import {MessagesService} from '../../../../core/services/messages.service';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchListComponent implements OnInit {
  public frequentlyAskedQuestions: Faq[] = [];
  public currentUser: User | null = null;
  public contractsOfEmployments: ContractOfEmployment[] = [];
  public messages: Message[] = [];
  public documents: Document[] = [];
  public expendedMessage: string = '';
  public searchValue: string | null = '';

  constructor(private route: ActivatedRoute,
              private crudService: CrudService,
              private authService: AuthenticationService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private messageSelectionService: MessagesService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.searchValue = routeParams.searchValue;
      this.onLoadSearchResults(this.searchValue);
      this.cd.detectChanges();
    });
  }
  private onLoadSearchResults(searchParams: any) {
    const paramsFAQs = this.buildParamsFAQs(searchParams);
    this.fetchFAQs(paramsFAQs);
    const paramsContracts = this.buildParamsContracts(searchParams);
    this.fetchContracts(paramsContracts);
    const paramsMessages = this.buildParamsMessages(searchParams);
    this.fetchMessages(paramsMessages);
    const paramsDocuments = this.buildParamsDocuments(searchParams);
    this.fetchDocuments(paramsDocuments);
  }
  private buildParamsFAQs(searchParams: string): { [key: string]: string } {
    return {
      'filter[title]': this.searchValue ? searchParams : ''
    };
  }

  private fetchFAQs(params: { [key: string]: string }) {
    this.crudService.fetchEntities<Faq>('frequently_asked_question', params).subscribe(response => {
        this.frequentlyAskedQuestions = response.data;
        this.cd.detectChanges();
      }
    );
  }

    private buildParamsContracts(searchParams: string): { [key: string]: string } {
      return {
        'filter[salary_classification]': this.searchValue ? searchParams : ''
      };
    }

    private fetchContracts(params: { [key: string]: string }) {
      this.crudService.fetchEntities<ContractOfEmployment>('contract_of_employment', params).subscribe(response => {
          this.contractsOfEmployments = response.data;
          this.cd.detectChanges();
        }
      );
    }

  private buildParamsMessages(searchParams: string): { [key: string]: string } {
    return {
      'filter[title]': this.searchValue ? searchParams : '',
      include: 'user',
    };
  }

  private fetchMessages(params: { [key: string]: string }) {
    this.crudService.fetchEntities<Message>('message', params).subscribe(response => {
          this.messages = response.data;
          this.cd.detectChanges();
        }
    );
  }

  private buildParamsDocuments(searchParams: string): { [key: string]: string } {
    return {
      'filter[name]': this.searchValue ? searchParams : ''
    };
  }

  private fetchDocuments(params: { [key: string]: string }) {
    this.crudService.fetchEntities<Document>('document', params).subscribe(response => {
          this.documents = response.data;
          this.cd.detectChanges();
        }
    );
  }

  public search(event: KeyboardEvent): void {
    this.router.navigate(['/search-list/' + (event.target as HTMLInputElement).value ]);
  }

  public onOpenMessage(message_id: string) {
    this.messageSelectionService.expandMessage(message_id);
    this.expendedMessage = message_id;
  }
}
