import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/common/login/login.component';
import {LogoutComponent} from './components/common/logout/logout.component';
import {BaseComponent} from './components/common/base/base.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthenticationInterceptor} from './core/interceptors/authentication.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {ResetPasswordRequestComponent} from './components/common/reset-password-request-modal/reset-password-request.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ResetPasswordComponent } from './components/common/reset-password/reset-password.component';
import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import {TreeViewModule} from '@syncfusion/ej2-angular-navigations';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { GridModule, CommandColumnService } from '@syncfusion/ej2-angular-grids';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { UploaderModule  } from '@syncfusion/ej2-angular-inputs';
import { LeftSidebarComponent } from './components/common/base/left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './components/common/base/right-sidebar/right-sidebar.component';
import { DashboardComponent } from './components/common/base/dashboard/dashboard.component';
import { FooterComponent } from './components/common/footer/footer.component';
import {CommonModule} from '@angular/common';
import { FAQComponent } from './components/common/base/faq/faq.component';
import { ProfileComponent } from './components/common/base/profile/profile.component';
import { SearchListComponent } from './components/common/base/search-list/search-list.component';
import { MessagesComponent } from './components/common/base/messages/messages.component';
import { MessageItemComponent } from './components/common/base/messages/message-item/message-item.component';
import { DocumentsComponent } from './components/common/base/documents/documents.component';
import { ContractEmploymentsComponent } from './components/common/base/contract-employments/contract-employments.component';
import { ContractEmploymentModalComponent } from './components/common/base/contract-employment-modal/contract-employment-modal.component';
import { VacationOverviewComponent } from './components/common/base/vacation-overview/vacation-overview.component';
import { VacationRequestModalComponent } from './components/common/base/vacation-request-modal/vacation-request-modal.component';
import { TimeTicketsUploadComponent } from './components/common/base/time-tickets-upload/time-tickets-upload.component';
import { DocumentsUploadComponent } from './components/common/base/documents-upload/documents-upload.component';
import {MenuMobileComponent} from './components/common/base/menu-mobile/menu-mobile.component';
import {SearchBoxComponent} from './components/common/base/search-box/search-box.component';
import {SignatureDialogComponent} from "./components/common/base/messages/signature-dialog/signature-dialog.component";
import {DocumentTypesComponent} from './components/common/base/document-types/document-types.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        BaseComponent,
        ResetPasswordRequestComponent,
        ResetPasswordComponent,
        LeftSidebarComponent,
        MenuMobileComponent,
        SearchBoxComponent,
        RightSidebarComponent,
        DashboardComponent,
        FooterComponent,
        FAQComponent,
        ProfileComponent,
        SearchListComponent,
        MessagesComponent,
        MessageItemComponent,
        DocumentsComponent,
        DocumentTypesComponent,
        ContractEmploymentsComponent,
        ContractEmploymentModalComponent,
        VacationOverviewComponent,
        VacationRequestModalComponent,
        TimeTicketsUploadComponent,
        DocumentsUploadComponent,
        SignatureDialogComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TextBoxAllModule,
        ButtonModule,
        DialogModule,
        ListViewAllModule,
        AccordionModule,
        TreeViewModule,
        TreeGridModule,
        GridModule,
        RadioButtonModule,
        NumericTextBoxModule,
        DropDownListModule,
        ChipListModule,
        DatePickerModule,
        UploaderModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        CommandColumnService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}
