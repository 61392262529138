<div class="gray-container h-100">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12 col-md-4 offset-md-4 d-flex align-items-center">
                <div class="login-form">
                    <div class="logo">
                        <img class="img-fluid" src="assets/layout/images/logo/hrep-logo.png">
                    </div>
                    <div class="login-item">
                        <form [formGroup]="form" (ngSubmit)="doReset()" class="form form-login">
                            <div class="e-input-group e-float-icon-left">
                                <span class="e-input-group-icon icon-key"></span>
                                <div class="e-input-in-wrap">
                                    <input formControlName="password" class="e-input" type="password"
                                           placeholder="Password"/>
                                </div>
                            </div>

                            <div class="e-input-group e-float-icon-left">
                                <span class="e-input-group-icon icon-key"></span>
                                <div class="e-input-in-wrap">
                                    <input formControlName="passwordConfirmation" class="e-input" type="password"
                                           placeholder="Confirm Password"/>
                                </div>
                            </div>
                            <div class="d-grid mb-3">
                                <button ejs-button cssClass="e-primary" size="large" type="button" (click)="doReset()">Reset password</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
