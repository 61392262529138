import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {User} from '../../../../core/models/user';
import {Timesheet} from '../../../../core/models/timesheet';
import {SelectedEventArgs} from '@syncfusion/ej2-angular-inputs';
import {FileUploadService} from '../../../../core/services/file-upload.service';

@Component({
  selector: 'app-time-tickets-upload',
  templateUrl: './time-tickets-upload.component.html',
  styleUrls: ['./time-tickets-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeTicketsUploadComponent implements OnInit {
  public current_user: User | null = null;
  public timesheets: Timesheet[] = [];
  public format = {type:'date', format:'dd/MM/yyyy'};
  public buttons: any = {
    browse: ''
  };

  constructor(
      private crudService: CrudService,
      private cd: ChangeDetectorRef,
      private authService: AuthenticationService,
      private fileUploadService: FileUploadService,
  ) { }

  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      const params = this.buildParams();
      this.fetchTimesheets(params);
      this.cd.detectChanges();
    });
  }

  public buildParams(): { [key: string]: string } {
    return  {
      include: 'user,file,assignment',
      'filter[user_id]': this.current_user?.id === undefined ? '' : this.current_user?.id,
    };
  }

  public fetchTimesheets(params: { [key: string]: string }): void {
    this.crudService.fetchEntities<Timesheet>('timesheet', params).subscribe(response => {
      this.timesheets = response.data;
      this.cd.detectChanges();
    });
  }

  upload(event: SelectedEventArgs, data: any) {
    this.fileUploadService.upload(event.filesData[0].rawFile).subscribe(response => {
      const payload = {file: response.data, id: data.id, user: data.user};
      this.crudService.updateEntity<Timesheet>('timesheet', data.id ?? '', payload)
          .subscribe(() => {
            this.fetchTimesheets(this.buildParams());
          });
    });
  }

  onClickUpload(event: any) {
    event.target.parentNode.parentNode.parentNode.querySelector('#dropArea').getElementsByTagName('button')[0].click();
  }

  public downloadAttachment(event: Event, item: any) {
    event.stopPropagation();
    const a = document.createElement("a"); //Create <a>
    a.href = item.file_url; //Image Base64 Goes here
    a.download = item.file_name; //File name Here
    a.target = '_blank';
    a.click();
  }
}
