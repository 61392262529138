<div class="row">
    <div class="col-12 hrep-headline d-flex align-items-center mb-4">
        <i class="icon-doc me-2"></i>
        <h6 class="small fw-bold mb-0">{{ 'COMMON.CONTRACT_OF_EMPLOYMENTS' | translate }}</h6>
    </div>
    <div class="hrep-accordion accordion-light mb-5">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'CONTRACT_EMPLOYMENTS.EMPLOYMENT_RELATIONSHIPS' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='contractEmployments' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column width='130'>
                                    <ng-template #template let-data class="p-0">
                                        <button (click)="onOpenDialog(data)" ejs-button
                                                cssClass="e-block e-small hrep-btn-light">Dienstverhältnis </button>
                                    </ng-template>
                                </e-column>
                                <e-column field='valid_from' headerText="{{ 'CONTRACT_EMPLOYMENTS.VALID_FROM' | translate }}" textAlign='Center' type="date"
                                          [format]="{type:'date', format:'dd.MM.yyyy'}"
                                          width='100'></e-column>
                                <e-column field='valid_to' headerText="{{ 'CONTRACT_EMPLOYMENTS.VALID_TO' | translate }}" textAlign='Center' type="date"
                                          [format]="{type:'date', format:'dd.MM.yyyy'}"
                                          width='100'></e-column>
                                <e-column field='salary_classification' headerText='Lohn / VWG' textAlign='Center'
                                          width='auto'></e-column>
                                <e-column field='employment_hours' headerText="{{ 'COMMON.HOURS' | translate }}" textAlign='Center' format='N'
                                          width=80></e-column>
                                <e-column width='25' textAlign='Center'>
                                    <ng-template #template let-data>
                                        <div *ngIf="data.file && data.file.has_to_be_signed && !data.file.signed_at"
                                             class="e-badge e-badge-danger e-badge-circle hrep-fill-icon">
                                            <span class="icon-exclamation"></span>
                                        </div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>

    <div class="hrep-accordion accordion-light">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'CONTRACT_EMPLOYMENTS.EMPLOYMENT' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid #grid class="hrep-grid" [dataSource]='assignments' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column width='130'>
                                    <ng-template #template let-data class="p-0">
                                        <button (click)="onOpenAssignmentDialog(data)" ejs-button
                                                cssClass="e-block e-small hrep-btn-light grid-action-btn">Beschäftigung</button>
                                    </ng-template>
                                </e-column>
                                <e-column field='valid_from' headerText="{{ 'COMMON.START' | translate }}" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                          width='100'></e-column>
                                <e-column field='valid_to' headerText="{{ 'COMMON.END' | translate }}" textAlign='Center' type="date" [format]="{type:'date', format:'dd.MM.yyyy'}"
                                          width='100'></e-column>
                                <e-column field='customer' headerText="{{ 'COMMON.CUSTOMER' | translate }}" textAlign='Center' width='auto'></e-column>
                                <e-column width='110' textAlign='Right'>
                                    <ng-template #template let-data>
                                        <div *ngIf="data.missingIndicator"
                                             class="e-badge e-badge-danger e-badge-circle hrep-fill-icon">
                                            <span class="icon-exclamation"></span>
                                        </div>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>

    <ejs-dialog id='dialog' #ejDialog showCloseIcon='true' [visible]="false"
                (beforeOpen)="beforeOpen($event)" (beforeClose)="beforeClose($event)"
                [target]='targetElement' cssClass="hrep-primary-dialog" width='1200px'>
        <ng-template #content>
            <app-contract-employment-modal *ngIf="isOpened" [employmentData]="contractEmployment"></app-contract-employment-modal>
        </ng-template>
        <ng-template #header>
            <div class="d-flex align-items-center justify-content-center position-relative">
                <span class="icon-doc me-2"></span>
                <div title="dienstverhältnis 1" class="e-icon-settings dlg-template text-uppercase">dienstverhältnis 1</div>
                <div class="position-absolute top-50 end-0 translate-middle-y">
                    <button ejs-button cssClass="hrep-icon-btn" iconCss="icon-printer"></button>
                    <button ejs-button cssClass="hrep-icon-btn pe-2" iconCss="icon-check"></button>
                </div>
            </div>
        </ng-template>
    </ejs-dialog>

</div>
